.Toastify__toast--error {
  background-color: red !important;
  color: white !important;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
}

@media (max-width: 480px) {
  .Toastify__toast-container {
    width: 320px;
    bottom: 1em;
    left: 1em;
  }
}
